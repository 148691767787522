<template>
    <div>
        <b-sidebar id="sidebar" aria-labelledby="sidebar-title" no-header bg-variant="dark" text-variant="dark" shadow backdrop backdrop-variant="dark">
            <template #default="{ hide }">
                <div class="py-2">
                    <nav class="mb-3">
                        <b-nav vertical class="pb-5">
                            <b-nav-item @click="hide" to="/">
                                <div style="display: flex;">
                                    <b-icon icon="house-door" class="mr-4"></b-icon>
                                    <span>Home</span>
                                </div>
                            </b-nav-item>
                            <b-nav-item v-if="permissions.stratification || permissions.campaigns"
                            @click="showStratification = !showStratification">
                                <div style="display: flex;">
                                    <b-icon icon="bar-chart" class="mr-4"></b-icon>
                                    <span>Estratificação</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showStratification ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showStratification" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.stratification" @click="hide" to="/stratification">
                                    <div style="display: flex;">
                                        <b-icon icon="bar-chart" class="ml-4 mr-4"></b-icon>
                                        <span>Estratificação</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.pending_rules" @click="hide" to="/pending-rules">
                                    <div style="display: flex;">
                                        <b-icon icon="journal-text" class="ml-4 mr-4"></b-icon>
                                        <span>Regras Pendentes</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.campaigns" @click="hide" to="/campaigns">
                                    <div style="display: flex;">
                                        <b-icon icon="megaphone" class="ml-4 mr-4"></b-icon>
                                        <span>Campanhas</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.cockpit_cockpit || permissions.cockpit_goals"
                            @click="showCockpit = !showCockpit">
                                <div style="display: flex;">
                                    <b-icon icon="speedometer2" class="mr-4"></b-icon>
                                    <span>Cockpit</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showCockpit ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showCockpit" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.cockpit_cockpit" @click="hide" to="/cockpit">
                                    <div style="display: flex;">
                                        <b-icon icon="speedometer2" class="ml-4 mr-4"></b-icon>
                                        <span>Cockpit</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.cockpit_goals" @click="hide" to="/goals">
                                    <div style="display: flex;">
                                        <b-icon icon="list-task" class="ml-4 mr-4"></b-icon>
                                        <span>Metas</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.messaging_messages || permissions.messaging_default_messages || permissions.messaging_tags || permissions.messaging_attachments || permissions.messaging_integration"
                            @click="showSubmenuMessaging = !showSubmenuMessaging">
                                <div style="display: flex;">
                                    <b-icon icon="chat-left" class="mr-4"></b-icon>
                                    <span>Mesa Renda Variável</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuMessaging ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuMessaging" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.messaging_messages" @click="hide" to="/messages">
                                    <div style="display: flex;">
                                        <b-icon icon="chat-left" class="ml-4 mr-4"></b-icon>
                                        <span>Mensagens</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.messaging_default_messages" @click="hide" to="/default-messages">
                                    <div style="display: flex;">
                                        <b-icon icon="files" class="ml-4 mr-4"></b-icon>
                                        <span>Mensagens Padrões</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.messaging_tags" @click="hide" to="/tags">
                                    <div style="display: flex;">
                                        <b-icon icon="hash" class="ml-4 mr-4"></b-icon>
                                        <span>Tags</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.messaging_attachments" @click="hide" to="/attachments">
                                    <div style="display: flex;">
                                        <b-icon icon="image" class="ml-4 mr-4"></b-icon>
                                        <span>Anexos</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.messaging_integration" @click="hide" to="/integrations">
                                    <div style="display: flex;">
                                        <b-icon icon="gear" class="ml-4 mr-4"></b-icon>
                                        <span>Integração</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.emails || permissions.default_emails || permissions.email_configuration"
                            @click="showSubmenuMessage = !showSubmenuMessage">
                                <div style="display: flex;">
                                    <b-icon icon="envelope" class="mr-4"></b-icon>
                                    <span>Mensageria</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuMessage ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuMessage" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.emails" @click="hide" to="/emails">
                                    <div style="display: flex;">
                                        <b-icon icon="envelope" class="ml-4 mr-4"></b-icon>
                                        <span>E-mails</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.default_emails" @click="hide" to="/default-emails">
                                    <div style="display: flex;">
                                        <b-icon icon="files" class="ml-4 mr-4"></b-icon>
                                        <span>E-mails Padrão</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.email_classification" @click="hide" to="/email-classification">
                                    <div style="display: flex;">
                                        <b-icon icon="tags" class="ml-4 mr-4"></b-icon>
                                        <span>Classificação de e-mail</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.email_configuration" @click="hide" to="/email-configuration">
                                    <div style="display: flex;">
                                        <b-icon icon="gear" class="ml-4 mr-4"></b-icon>
                                        <span>Configuração E-mail</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.top_funds || permissions.top_pension_funds"
                            @click="showSubmenuTopFunds = !showSubmenuTopFunds">
                                <div style="display: flex;">
                                    <b-icon icon="graph-up" class="mr-4"></b-icon>
                                    <span>Top Funds</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuTopFunds ? 'caret-down' : 'caret-up'"></b-icon>
                                </div> 
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuTopFunds" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.top_funds" @click="hide" to="/top-funds">
                                    <div style="display: flex;">
                                        <b-icon icon="bar-chart-fill" class="ml-4 mr-4"></b-icon>
                                        <span>Top Funds</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.top_pension_funds" @click="hide" to="/top-pension-funds">
                                    <div style="display: flex;">
                                        <b-icon icon="safe-2" class="ml-4 mr-4"></b-icon>
                                        <span>Top Funds Previdência</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.portfolio_comparison" @click="hide" to="/portfolio-comparison">
                                    <div style="display: flex;">
                                        <b-icon icon="graph-up" class="ml-4 mr-4"></b-icon>
                                        <span>Comparação de carteiras</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.profile" @click="hide" target="_blank" href="https://profiles.wflowinvest.app/login">
                                <div style="display: flex;">
                                    <b-icon icon="person-plus" class="mr-4"></b-icon>
                                    <span>Profile</span>
                                </div>
                            </b-nav-item>
                            <b-nav-item v-if="permissions.pipefy" @click="hide" target="_blank" href="https://app.pipefy.com/portals/28ff3f99-ac43-4eaf-be43-876fb2abccb8">
                                <div style="display: flex;">
                                    <b-icon icon="clipboard-check" class="mr-4"></b-icon>
                                    <span>Pipefy</span>
                                </div>
                            </b-nav-item>
                            <b-nav-item v-if="permissions.report_fgc || permissions.report_rf_maturities"
                            @click="showSubmenuReports = !showSubmenuReports">
                                <div style="display: flex;">
                                    <b-icon icon="file-bar-graph" class="mr-4"></b-icon>
                                    <span>Relatórios</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuReports ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuReports" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.report_fgc" @click="hide" to="/fgc">
                                    <div style="display: flex;">
                                        <b-icon icon="graph-up" class="ml-4 mr-4"></b-icon>
                                        <span>FGC</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.report_rf_maturities" @click="hide" to="/report-rf-maturities">
                                    <div style="display: flex;">
                                        <b-icon icon="calendar3" class="ml-4 mr-4"></b-icon>
                                        <span>Vencimentos RF</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.register_customers || permissions.register_advisors || permissions.register_commercial_partners || permissions.register_users || permissions.register_companies || permissions.register_cells || permissions.register_wflow_objectives || permissions.register_bank"
                            @click="showSubmenu = !showSubmenu">
                                <div style="display: flex;">
                                    <b-icon icon="collection" class="mr-4"></b-icon>
                                    <span>Cadastros</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenu ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenu" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.register_customers" @click="hide" to="/customers">
                                    <div style="display: flex;">
                                        <b-icon icon="people" class="ml-4 mr-4"></b-icon>
                                        <span>Clientes</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_advisors" @click="hide" to="/advisors">
                                    <div style="display: flex;">
                                        <b-icon icon="people" class="ml-4 mr-4"></b-icon>
                                        <span>Assessores</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_commercial_partners" @click="hide" to="/commercial-partner">
                                    <div style="display: flex;">
                                        <b-icon icon="shop" class="ml-4 mr-4"></b-icon>
                                        <span>Parceiro Comercial</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_users" @click="hide" to="/users">
                                    <div style="display: flex;">
                                        <b-icon icon="person" class="ml-4 mr-4"></b-icon>
                                        <span>Usuários</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_companies" @click="hide" to="/companies">
                                    <div style="display: flex;">
                                        <b-icon icon="building" class="ml-4 mr-4"></b-icon>
                                        <span>Unidades</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_cells" @click="hide" to="/cells">
                                    <div style="display: flex;">
                                        <b-icon icon="diagram2" class="ml-4 mr-4"></b-icon>
                                        <span>Células</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_wflow_objectives" @click="hide" to="/objectives">
                                    <div style="display: flex;">
                                        <b-icon icon="graph-up" class="ml-4 mr-4"></b-icon>
                                        <span>Objetivos WFLOW</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.register_bank" @click="hide" to="/banks">
                                    <div style="display: flex;">
                                        <b-icon icon="bank" class="ml-4 mr-4"></b-icon>
                                        <span>Bancos</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.working_days_ptax" @click="hide" to="/working-days-ptax">
                                    <div style="display: flex;">
                                        <b-icon icon="calendar3" class="ml-4 mr-4"></b-icon>
                                        <span>Dias utéis & PTAX</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.import_stratification || permissions.import_daily_diversification || permissions.import_cockpit"
                            @click="showSubmenuImportations = !showSubmenuImportations">
                                <div style="display: flex;">
                                    <b-icon icon="cloud-upload" class="mr-4"></b-icon>
                                    <span>Importações</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuImportations ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuImportations" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.import_stratification" @click="hide" to="/import-estratification">
                                    <div style="display: flex;">
                                        <b-icon icon="file-earmark-excel" class="ml-4 mr-4"></b-icon>
                                        <span>Estratificação</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.import_daily_diversification" @click="hide" to="/import-diversification-daily">
                                    <div style="display: flex;">
                                        <b-icon icon="file-earmark-excel" class="ml-4 mr-4"></b-icon>
                                        <span>Diversificação diária</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.import_cockpit" @click="hide" to="/import-cockpit">
                                    <div style="display: flex;">
                                        <b-icon icon="file-earmark-excel" class="ml-4 mr-4"></b-icon>
                                        <span>Cockpit</span>
                                    </div>
                                </b-nav-item>
                                <b-nav-item v-if="permissions.import_customer" @click="hide" to="/import-customer">
                                    <div style="display: flex;">
                                        <b-icon icon="file-earmark-excel" class="ml-4 mr-4"></b-icon>
                                        <span>Cliente XP</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.config_permissions"
                            @click="showSubmenuConfig = !showSubmenuConfig">
                                <div style="display: flex;">
                                    <b-icon icon="gear" class="mr-4"></b-icon>
                                    <span>Configurações</span>
                                </div>
                                <div>
                                    <b-icon :icon="!showSubmenuConfig ? 'caret-down' : 'caret-up'"></b-icon>
                                </div>
                            </b-nav-item>
                            <b-collapse v-model="showSubmenuConfig" class="subMenu" style="background-color: #C86C55">
                                <b-nav-item v-if="permissions.config_permissions" @click="hide" to="/permissions">
                                    <div style="display: flex;">
                                        <b-icon icon="lock" class="ml-4 mr-4"></b-icon>
                                        <span>Permissões</span>
                                    </div>
                                </b-nav-item>
                            </b-collapse>
                            <b-nav-item v-if="permissions.log" @click="hide" to="/logs">
                                <div style="display: flex;">
                                    <b-icon icon="eyeglasses" class="mr-4"></b-icon>
                                    <span>Log</span>
                                </div>
                            </b-nav-item>
                        </b-nav>
                    </nav>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SidebarComponent',
    data() {
        return {
            showStratification: false,
            showSubmenu: false,
            showSubmenuConfig: false,
            showSubmenuReports: false,
            showSubmenuImportations: false,
            showSubmenuMessaging: false,
            showSubmenuMessage: false,
            showSubmenuTopFunds: false,
            showCockpit: false,
            permissions: this.emptyPermissions(),
        };
    },
    mounted(){
        const intervalId = setInterval(()=>{
            if(this.user){
                clearInterval(intervalId);
                this.getPermissions();
                this.validateRoute();
            }
        }, 0)
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods:{
        getPermissions(){ this.permissions = this.user.permissions?.id ? this.user.permissions : this.emptyPermissions(); },
        validateRoute(){
            if(
                ( this.$route.name == 'stratification' && !this.permissions.stratification ) ||
                ( this.$route.name == 'pendingRules' && !this.permissions.pending_rules ) ||
                ( this.$route.name == 'campaigns' && !this.permissions.campaigns ) ||

                ( this.$route.name.includes('customer') && !this.permissions.register_customers ) ||
                ( this.$route.name.includes('advisor') && !this.permissions.register_advisors ) ||
                ( this.$route.name.includes('commercialPartner') && !this.permissions.register_commercial_partners ) ||
                ( this.$route.name.includes('users') && !this.permissions.register_users ) ||
                ( this.$route.name.includes('companies') && !this.permissions.register_companies ) ||
                ( this.$route.name.includes('cells') && !this.permissions.register_cells ) ||
                ( this.$route.name.includes('objectives') && !this.permissions.register_wflow_objectives ) ||
                ( this.$route.name == 'banks' && !this.permissions.register_bank ) ||

                ( this.$route.name.includes('permissions') && !this.permissions.config_permissions ) ||

                ( this.$route.name == 'fgc' && !this.permissions.report_fgc ) ||
                ( this.$route.name == 'reportRfMaturities' && !this.permissions.report_rf_maturities ) ||

                ( this.$route.name == 'importEstratification' && !this.permissions.import_stratification ) ||
                ( this.$route.name == 'importDiversificationDaily' && !this.permissions.import_daily_diversification ) ||
                ( this.$route.name == 'importCockpit' && !this.permissions.import_cockpit ) ||
                ( this.$route.name == 'importCustomer' && !this.permissions.import_customer ) ||

                ( this.$route.name.includes('emails') && !this.$route.name.includes('default') && !this.permissions.emails ) ||
                ( this.$route.name.includes('default-emails') && !this.permissions.default_emails ) ||
                ( this.$route.name.includes('email-configuration') && !this.permissions.email_configuration ) ||

                ( this.$route.name.includes('top-funds') && !this.permissions.top_funds ) ||
                ( this.$route.name.includes('top-pension-funds') && !this.permissions.top_pension_funds ) ||
                ( this.$route.name.includes('portfolio_comparison') && !this.permissions.portfolio_comparison ) ||

                ( this.$route.name.includes('message') && !this.permissions.messaging_messages ) ||
                ( this.$route.name.includes('defaultMessage') && !this.permissions.messaging_default_messages ) ||
                ( this.$route.name.includes('tags') && !this.permissions.messaging_tags ) ||
                ( this.$route.name.includes('attachments') && !this.permissions.messaging_attachments ) ||
                ( this.$route.name.includes('Integration') && !this.permissions.messaging_integration ) ||

                ( this.$route.name.includes('cockpit') && !this.permissions.cockpit_cockpit ) ||
                ( this.$route.name.includes('goals') && !this.permissions.cockpit_goals ) || 

                ( this.$route.name.includes('log') && !this.permissions.log )
            ){
                this.$router.go(-1);
                return;
            }
        },
        emptyPermissions(){
            return {
                "stratification": false,
                "pending_rules": false,
                "campaigns": false,

                "profile": false,

                "pipefy": false,

                "register_customers": false,
                "register_advisors": false,
                "register_commercial_partners": false,
                "register_users": false,
                "register_companies": false,
                "register_cells": false,
                "register_wflow_objectives": false,
                "register_bank": false,

                "config_permissions": false,

                "report_fgc": false,
                "report_rf_maturities": false,

                "import_stratification": false,
                "import_daily_diversification": false,
                "import_cockpit": false,
                "import_customer": false,
                
                "messaging_messages": false,
                "messaging_default_messages": false,
                "messaging_tags": false,
                "messaging_attachments": false,
                "messaging_integration": false,

                "emails": false,
                "default_emails": false,
                "email_configuration": false,

                "top_funds": false,
                "top_pension_funds": false,

                "cockpit_cockpit": false,
                "cockpit_goals": false,
                "log": false,
            };
        },
    }
}
</script>

<style>
    /* Sidebar */
    #sidebar {
        background-color: #C34D2F !important;
        color: #FFF !important;
        top: 62px !important;
        height: 100vh;
        width: 280px;
    }

    #sidebar .nav-item > .nav-link {
        color: #FFF;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
    }

    #sidebar .nav-item > .nav-link:hover {
        color: #CCC;
    }
</style>
