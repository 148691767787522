<template>
    <b-row style="min-height: 98px;" class="px-4">
        <b-col v-if="inputLabel" cols="3" class="d-flex align-items-start justify-content-center flex-column p-0 mr-4">
            <div class="form-group pr-4">
                <label style="position:absolute; top:10px; left:15px;"
                :id="inputRef" :label-for="inputRef"> {{ inputLabel }} </label>
                <input class="form-control form-control-sm" :id="inputRef" :name="inputRef"
                @input="(value)=>$emit('input', value)"
                style="min-height:56px;" v-model="inputValue" required>
            </div>

            <!-- <b-form-group :id="'inputGroup'+inputRef" :label="inputLabel" :label-for="'inputGroup'+inputRef" class="form-label">
                <input
                    @input="(value)=>$emit('input', value)"
                    :id="'input'+inputRef"
                    :name="'input'+inputRef"
                    v-model="inputValue"
                    class="form-control"
                    style="min-height:56px;"
                    required
                />
            </b-form-group> -->
        </b-col>
        <b-col v-else cols="3" class="d-flex align-items-start justify-content-center flex-column p-0 mr-4">
            <span style="font-size: 22px; font-wight: 600;"> {{ title }} </span>
            <span v-if="quotation" style="font-size: 16px; font-wight: 600; color: #eb694a; line-height:5px;"> Cotação: {{ quotation }} </span>
        </b-col>

        <slot></slot>

        <b-col class="px-5" style="border: 1px solid #535252; border-radius: 8px; background-color: #212020; display: flex; align-items: center; justify-content: space-between">
            <!-- <div v-if="loading || disabled" @click="clickDisabled" style="width:100%;height:100%; position:absolute;top:0px;left:0px; z-index:2;"></div> -->
            <span v-if="!file && status==0" class="d-flex">
                <!-- :borderColor="'transparent'"
                :bgColor="'#EB694A20'"
                :textColor="'#EB694A'" -->
                <GlassButton
                :loading="loading"
                @click="openFileImport"
                class="mr-2"
                > 
                    <span> Escolher arquivo ... </span>
                </GlassButton>
                <GlassButton
                :loading="loading"
                @click="ignoreImport(inputRef)"
                v-if="ignoreButton"
                > 
                    <span> Ignorar este mês </span>
                </GlassButton>
                <!-- <a href="#" class="ml-4">
                    Arraste o arquivo aqui
                </a> -->
                <input 
                    type="file"
                    :ref="inputRef"
                    style="display: none;"
                    @change="handleFileImport"
                />
            </span>
            <b-row v-else align-v="center" align-h="between" class="mx-0 w-100 mr-4">
                <div>
                    <b-icon v-if="status==1" icon="hourglass-split" class="mr-2" style="color:#FFA000"></b-icon>
                    <b-icon v-else-if="status==2" icon="check-circle" class="mr-2" style="color:#00FF29"></b-icon>
                    <b-icon v-else-if="status==3" icon="x-circle" class="mr-2" style="color:#E90000"></b-icon>
                    {{ status==1 ? "Importação em andamento" : file?.name || file_name }}
                    {{ status==3 ? inputRef.replace('ref', '').replace('TabelaComissaoPJ2', 'Tabela de comissão PJ2') + " ignorado este mês" : ""}}
                </div>
                
            </b-row>
            <b-row style="min-width: max-content;" align-v="center">
                    <b-button v-if="file && status==0" variant="danger" @click="clear" class="mr-3"
                    style="background:transparent !important; color:red !important; border:none !important;"
                    >
                        <b-icon icon="x-circle"></b-icon>
                    </b-button>
                    <span v-else-if="status==2 && importedDate" class="mr-3" style="font-size:18px; font-weight:500;">
                        {{ importedDate | formatDate }}
                    </span>

                    <GlassButton v-if="!file && status==0"
                    disabled
                    > 
                        <span> Importar </span>
                    </GlassButton>
                    <CustomButton v-else-if="file && status==0"
                    @click="postImport"
                    > 
                        <span> Importar </span>
                    </CustomButton>
                    <CustomButton v-else-if="status==1"
                    bgColor="#FFA000"
                    borderColor="#FFA000"
                    not_clickable
                    > 
                        <span> Importando </span>
                    </CustomButton>
                    <CustomButton v-else-if="status==2"
                    bgColor="#28A745"
                    borderColor="#28A745"
                    not_clickable
                    > 
                        <span> Importado </span>
                    </CustomButton>
            </b-row>
            <!-- <span>
                <img src="@/assets/calc.png" alt="" class="mr-2" v-if="quantity === 0">
                <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                <label :style="quantity === 0 ? '' : 'color: #4CAF50;'">{{ quantity }} / 1</label>
            </span> -->
        </b-col>
    </b-row>
</template>

<script>
import GlassButton from './GlassButton.vue';
import CustomButton from './CustomButton.vue';
export default {
    name: 'ImportationComponent',
    components:{
        GlassButton,
        CustomButton,
    },
    props: {
        status: {
            type: Number,
            default: 0
        },
        file_name: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Tabelas'
        },
        quotation: {
            type: String,
            default: null,
        },
        importedDate: {
            type: String,
            default: ''
        },
        inputLabel: {
            type: String,
            default: ''
        },
        inputRef: {
            type: String,
            default: 'inputFile'
        },
        allowedFormats: {
            type: Array,
            default: ()=>{ return ['xlsx', 'xls', 'csv']; }
        },
        disabled: Boolean,
        showDelete: Boolean,
        ignoreButton: Boolean
    },
    data(){
        return {
            loading: false,
            file: null,
            inputValue: null,
        }
    },
    methods: {
        clickDisabled(){
            if(this.disabled || this.loading) {
                this.$emit('clickDisabled')
                return;
            }
        },
        openFileImport (){
            if (this.loading) return;
            this.$refs[this.inputRef].click();
        },
        async ignoreImport(inputRef) {
            let reference_id = '';
            switch (inputRef) {
                case "refWFLOWINVES":
                    reference_id = 6;
                    break;
                case "refWFLOWINVES XP US":
                    reference_id = 7;
                    break;
                case "refWFLOWINVES Mercado Internacional":
                    reference_id = 8;
                    break;
                case "refWFLOWINVES Co-corretagem XPVP ":
                    reference_id = 9;
                    break;
                case "refWFLOWINVES Co-corretagem Terceiras":
                    reference_id = 10;
                    break;
                case "refWFLOWINVES XPCS ":
                    reference_id = 11;
                    break;
                case "refWFLOWINVES Crédito ":
                    reference_id = 12;
                    break;
                case "refWFLOWINVES Câmbio":
                    reference_id = 13;
                    break;
                case "refWFLOWINVES Erro Operacional":
                    reference_id = 14;
                    break;
                case "refWFLOWINVES Complemento RF":
                    reference_id = 15;
                    break;
                case "refWFLOWINVES Campanhas":
                    reference_id = 16;
                    break;
                case "refTabelaComissaoPJ2":
                    reference_id = 17;
                    break;
            }

            this.$emit('ignore', reference_id)
        },
        postImport(){
            if(this.inputLabel) 
                this.$emit('import', this.inputValue, this.file, true)
            else this.$emit('import', this.title, this.file, false)
        },
        clear(){
            this.file=null;
            this.$emit('change', this.file, this.title)
        },
        handleFileImport(event){
            const file = event.target.files[0];
            if(this.allowedFormats.find(e=>e==file.name.split('.').pop())){
                this.file = file;
                this.$emit('change', this.file, this.title)
            }
            else{
                this.$swal({
                    title: 'Arquivo inválido',
                    text: 'Arquivo inválido, por favor, verifique o modelo de importação.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        
    },
}
</script>

<style scoped>

</style>