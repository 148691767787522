<template>
    <div id="dashboard">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação de tabelas para estratificação referente a: <b style=\'color:#eb694a;\'> '+ getDateTitle() +' </b>'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        not_scroll_top
        >

        <!-- <template #header>
            <b-col class="px-0">
                <GlassButton
                > Importações
                </GlassButton>
            </b-col>
        </template> -->

        <!-- CONTENT -->
        <div class="py-3">



            <b-row class="m-0">
                <span style="color: #eb694a;font-size: 22px;">
                    Guias
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Guia de Fundos"
            :inputRef="'refGuiaFundos'"
            :status="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Guia de Fundos')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>
            
            <div class="py-2"></div>

            <ImportationVariantComponent class="m-0"
            title="Guia de fundos previdência"
            :inputRef="'refGuiaFundosPrev'"
            :status="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>



            <div class="pb-3" style="position: relative;">

            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Custódias
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Diversificação"
            :inputRef="'refDiversificacao'"
            :status="data.find(e=>e.table_name.trim()=='Diversificação')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Diversificação')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Diversificação')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Diversificação')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>
            
            <div class="py-2"></div>

            <ImportationVariantComponent class="m-0"
            title="XPUS"
            :quotation="String(quotation) || 'Sem informação'"
            :inputRef="'refXPUS'"
            :status="data.find(e=>e.table_name.trim()=='XPUS')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='XPUS')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='XPUS')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='XPUS')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>

            


            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Contratação Fee Fixo
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0"
            title="Contratação Fee Fixo"
            :inputRef="'refContratacaoFeeFixo'"
            :status="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.loading"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent>

            <!-- Block Custódias -->
            <div v-if="blockCustodies" class="h-100 w-100" style="opacity:0.2; position:absolute; top:0; background-color:white; z-index:2;"></div>

            </div>



            <div class="pb-3" style="position:relative;">

            <b-row class="m-0 pt-3" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Comissões
                </span>
            </b-row>
            
            <div v-for="item,idx in listCommission" :key="idx">
                <ImportationVariantComponent class="m-0"
                :title="item.table_name"
                :inputRef="'ref'+item.table_name"
                :quotation="hasQuotation(item.table_name) ? (String(quotation) || 'Sem informação') : null"
                :status="item.status"
                :file_name="item.file_name"
                :importedDate="item.created_at_date"
                :loading="item.loading"
                @change="getFile"
                @import="postImport"
                @ignore="ignore"
                :ignoreButton="true"
                ></ImportationVariantComponent>
                
                <div v-if="idx!=listCommission.length-1" class="py-2"></div>
            </div>



            <!-- <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Nova tabela de comissão
                </span>
            </b-row>

            <ImportationVariantComponent v-if="renderNew" class="m-0"
            inputLabel="Nome da tabela"
            :inputRef="'refNewTable'"
            @input="getNewTable"
            @change="getFile"
            @import="postImport"
            ></ImportationVariantComponent> -->

            <!-- <div class="py-2 mt-1"></div>

            <CustomButton class="px-4"
            :width="'100%'"
            >
                Processar Comissões PJ1
            </CustomButton> -->




            <b-row class="m-0 pt-3 mt-4" style="border-top:1px solid #535252;">
                <span style="color: #eb694a;font-size: 22px;">
                    Comissões PJ2
                </span>
            </b-row>
            <ImportationVariantComponent class="m-0 mb-5"
            title="Tabela de comissão PJ2"
            :inputRef="'refTabelaComissaoPJ2'"
            :status="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.status"
            :file_name="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.file_name"
            :importedDate="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.created_at_date"
            :loading="data.find(e=>e.table_name.trim()=='Tabela de comissão PJ2')?.loading"
            @change="getFile"
            @import="postImport"
            @ignore="ignore"
            :ignoreButton="true"
            ></ImportationVariantComponent>


            <!-- Block Comissões -->
            <div v-if="blockCommissions" class="h-100 w-100" style="opacity:0.2; position:absolute; top:0; background-color:white; z-index:2;"></div>

            </div>
            
        </div>

        </DefaultPageComponent>

    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
// import GlassButton from '@/components/GlassButton.vue';
// import CustomButton from '@/components/CustomButton.vue';
import ImportationVariantComponent from '@/components/ImportationVariantComponent.vue';
export default {
    name: 'ImportView',
    components:{
        // CustomButton,
        // GlassButton,
        DefaultPageComponent,
        ImportationVariantComponent,
    },
    data () {
        return {
            renderNew: true,
            loading: false,
            loadingSubmit: false,
            quotation: null,
            year: null,
            month: null,
            reference_date: null,
            new_table_name: null,
            data: [],
            listCommission: [],
            hiddenList: [],
            importingList: [],

        }
    },
    async mounted() {
        await this.getReferenceDate();
        this.getDateTitle();
        this.getQuotation();
        this.getStratification();
    },
    computed:{
        blockCustodies(){
            return !(
                this.data.find(e=>e.table_name.trim()=='Guia de Fundos')?.status == 2
                && this.data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.status == 2
            );
        },
        blockCommissions(){
            return !(
                this.data.find(e=>e.table_name.trim()=='Guia de Fundos')?.status == 2
                && this.data.find(e=>e.table_name.trim()=='Guia de fundos previdência')?.status == 2
                && this.data.find(e=>e.table_name.trim()=='Diversificação')?.status == 2
                && this.data.find(e=>e.table_name.trim()=='XPUS')?.status == 2
                && this.data.find(e=>e.table_name.trim()=='Contratação Fee Fixo')?.status == 2
            );
        },
    },
    methods: {
        loopGet(){
            const intervalId = setInterval(() => {
                if(this.importingList.length==0)
                    clearInterval(intervalId)
                this.getStratificationSilent();
            }, 30000);
        },
        async getFile(file, table_name){
            var idx = this.data.findIndex(e=>e.table_name.trim()==table_name);
            if(idx>=0) this.data[idx].file=file;
        },
        async getQuotation(){
            var day = this.reference_date.substring(8,10);
            var month = this.reference_date.substring(5,7);
            var year = this.reference_date.substring(0,4);
            const date = month+'-'+day+'-'+year;
            const url = "https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?@dataCotacao='"+ date +"'&$top=1&$format=json&$select=cotacaoCompra"
            const response = await this.$axios.get(url);
            this.quotation = response.data.value[0]?.cotacaoCompra || null;
        },
        async getReferenceDate(){
            const response = await this.$axios.get('calculate-reference-date');
            this.reference_date = response.data.reference_date;
        },
        getListCommission(){
            const idxStart = this.data.findIndex(e=>e.table_name.trim()=='Contratação Fee Fixo');
            const idxEnd = this.data.findIndex(e=>e.table_name.trim()=='Tabela de comissão PJ2');
            this.listCommission = [...this.data].splice(idxStart+1, idxEnd-idxStart-1);
        },
        async getStratification(){
            try{
                this.loading=true;
                const response = await this.$axios.get('import-status-details?type=Estratificação&year='+this.year+'&month='+this.month);
                this.data = response.data;
                let showAlert = true;
                this.data.forEach((item) => {
                    if(item.status!=3 && item.status!=2)
                        showAlert = false;
                });
                if(showAlert){
                    this.$swal({
                        title: 'Importações concluídas',
                        text: 'As importações deste mês foram concluídas.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                    })
                }
                this.importingList = response.data.filter(e=>e.status==1);
                this.getListCommission()
                this.loopGet();
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível carregar as importações.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            }
            finally{
                this.loading=false;
            }
        },
        async getStratificationSilent(){
            try{
                const response = await this.$axios.get('import-status-details?type=Estratificação&year='+this.year+'&month='+this.month);
                this.hiddenList = response.data;
                var hiddenFiltered = this.hiddenList.filter(e=>e.status==1);
                if(this.importingList.length!=hiddenFiltered.length){
                    var oldImported = this.importingList.find(e=>!hiddenFiltered.find(h=>h.table_name==e.table_name));
                    var idx = this.data.findIndex(e=>e.table_name==oldImported.table_name);
                    var newImported = this.hiddenList.find(e=>e.table_name==oldImported.table_name);
                    this.$set(this.data, idx, newImported);
                }
                this.importingList = hiddenFiltered;
            }
            catch(error){ console.log(error); }
        },
        async forceRenderNew(){
            this.renderNew=false;
            await this.$nextTick();
            this.renderNew=true;
        },
        async postImport(title, file, isNew=false){
            if(isNew && !title){
                this.$swal({
                    title: 'Atenção',
                    text: `Preencha o nome da tabela antes de importar.`,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                });
                return;
            }

            try{
                this.loading=true;

                var endpoint = null;

                if(isNew) endpoint = 'commissions/import';
                else if(title.trim()=='Guia de Fundos') endpoint = 'funds/import';
                else if(title.trim()=='Guia de fundos previdência') endpoint = 'pension-guide/import';
                else if(title.trim()=='Diversificação') endpoint = 'diversifications/import';
                else if(title.trim()=='XPUS') endpoint = 'diversifications/xp-us/import';
                else if(title.trim()=='Contratação Fee Fixo') endpoint = 'contract-fee-fixo/import';
                else if(title.trim()=='WFLOWINVES XP US') endpoint = 'commissions/usd/import';
                else if(title.trim()=='WFLOWINVES Mercado Internacional') endpoint = 'commissions/usd/import';
                else if(this.listCommission.find(e=>e.table_name==title)) endpoint = 'commissions/import';
                else if(title.trim()=='Tabela de comissão PJ2') endpoint = 'commission-only/import';

                const formData = new FormData();
                formData.append('sheet', file);

                await this.$axios.post(endpoint+'?table_name='+title.trim(), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                this.forceRenderNew();

                // if(isNew) this.$router.go();
                // else 
                this.getStratification();
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível realizar a importação.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            }
            
        },
        getNewTable(title){
            this.new_table_name=title;
        },

        hasQuotation(table_name){
            switch(table_name){
                case 'WFLOWINVES XP US':
                    return true;
                case 'WFLOWINVES Mercado Internacional':
                    return true;
                default:
                    return false;
            }
        },

        getDateTitle(){
            const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
            const dataAtual = new Date(this.reference_date);
            const ano = dataAtual.getFullYear();
            const mes = dataAtual.getMonth();
            const mesString = months[mes].toUpperCase();
            this.month = mes+1;
            this.year = ano;
            return mesString + ' ' + ano;
        },
        async ignore(reference_id) {
            let table_name = '';

            switch (reference_id) {
                case 6:
                    table_name = "WFLOWINVES";
                    break;
                case 7:
                    table_name = "WFLOWINVES XP US";
                    break;
                case 8:
                    table_name = "WFLOWINVES Mercado Internacional";
                    break;
                case 9:
                    table_name = "WFLOWINVES Co-corretagem XPVP";
                    break;
                case 10:
                    table_name = "WFLOWINVES Co-corretagem Terceiras";
                    break;
                case 11:
                    table_name = "WFLOWINVES XPCS";
                    break;
                case 12:
                    table_name = "WFLOWINVES Crédito";
                    break;
                case 13:
                    table_name = "WFLOWINVES Câmbio";
                    break;
                case 14:
                    table_name = "WFLOWINVES Erro Operacional";
                    break;
                case 15:
                    table_name = "WFLOWINVES Complemento RF";
                    break;
                case 16:
                    table_name = "WFLOWINVES Campanhas";
                    break;
                case 17:
                    table_name = "Tabela de comissão PJ2";
                    break;
            }

            this.$swal({
                title: 'Ignorar importação ?',
                text: 'Deseja realmente ignorar a importação da tabela ' + table_name + ' neste mês?',
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText:  'Ignorar tabela',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    await this.$axios.put("import-status/" + reference_id, {
                        'status': 3
                    });

                    this.forceRenderNew();

                    this.getStratification();
                }
            });
        }
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
