<template>
    <div id="dashboard" style="height:100%;">
        <DefaultPageComponent 
        :title="'Estratificação'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        v-show="!details"
        :loading="loading"
        >
            <template #filters>
                <b-row>
                    <b-col class="d-flex">
                        <div class="d-flex flex-column mr-4" :style="fieldFilters.type ? 'opacity:.5;' : ''">
                            <label for="inputPesquisarPor">Visão:</label>
                            <select :disabled="fieldFilters.type" class="form-control form-control-sm mr-2" id="inputPesquisarPor" style="box-shadow: none; min-width: 240px; max-width: 240px; min-height:56px;" v-model="filterBy" @change="advisorId = ''; cellId = ''; getCells(); getAdvisors();">
                                <option value="company" v-if="user.role == 1">WFLOW</option>
                                <option value="cell">Equipe</option>
                                <option value="advisor" v-if="user.role == 1">Assessor</option>
                                <option value="finder" v-if="user.hunter_ids.length > 1">Finder</option>
                            </select>
                        </div>
                        <div class="d-flex flex-column mr-4" v-if="filterBy === 'cell' || filterBy === 'finder'" :style="fieldFilters.team || filterBy === 'finder'? 'opacity:.5;' : ''">
                            <label for="inputPesquisarPor">Equipe:</label>
                            <select :disabled="fieldFilters.team || filterBy === 'finder'" class="form-control form-control-sm mr-2" id="inputPesquisarPor" style="box-shadow: none; min-width: 240px; max-width: 240px; min-height:56px;" v-model="cellId" @change="advisorId = ''; getAdvisors(cellId);">
                                <option value="">Selecione</option>
                                <option v-for="cell in cells" :key="cell.id" :value="cell.id">{{ cell.cell }}</option>
                            </select>
                        </div>
                        <div class="d-flex flex-column mr-4" v-if="filterBy === 'cell' || filterBy === 'advisor' || filterBy === 'finder'" :style="fieldFilters.advisor ? 'opacity:.5;' : ''">
                            <label for="inputPesquisarPor">Assessor:</label>
                            <select :disabled="fieldFilters.advisor" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-width: 240px; max-width: 240px; min-height:56px;" v-model="advisorId" @change="getStratification">
                                <option value="">Selecione</option>
                                <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">{{ advisor.first_name + " " + advisor.last_name }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="d-flex align-items-center justify-content-end">
                        <b-form-checkbox :disabled="fieldFilters.fee" :style="fieldFilters.fee ? 'opacity:.5;' : ''" class="mt-4 mr-4" switch size="md" v-model="customerFeeFixo" @change="getStratification">Ignorar Fee Fixo</b-form-checkbox>
                        <select @change="getStratification"
                        :disabled="fieldFilters.period" :style="fieldFilters.period ? 'opacity:.5;' : ''"
                        v-model="period"
                        type="month" 
                        class="form-control form-control-sm mt-4 text-right pr-5" 
                        id="inputFilterDate" 
                        style="box-shadow: none; max-width: 240px; min-height:56px;">
                            <option v-for="item in periods" :key="item" :value="item"> {{ item }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="mt-4 mb-2">
                    <b-col>
                        <StratificationCard
                        title="WFLOW"
                        :custody="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? 'R$ ' + stratification.custody.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        :receive="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? 'R$ ' + stratification.receive.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        :roa="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? stratification.roa.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' : '---'"
                        />
                    </b-col>
                    <div class="px-3"></div>
                    <b-col>
                        <StratificationCard
                        title="PJ1"
                        :custody="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? 'R$ ' + stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL')?.custody_by_classification?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        :receive="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? 'R$ ' + stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL')?.receive_by_classification?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        :roa="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL')?.roa_xp.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' : '---'"
                        />
                    </b-col>
                    <div class="px-3"></div>
                    <b-col>
                        <StratificationCard
                        title="PJ2"
                        :custody="stratification.classifications.filter(e => e.classification_name.toUpperCase() == 'TOTAL')[1] ? 'R$ ' + stratification.classifications.filter(e => e.classification_name.toUpperCase() == 'TOTAL')[1]?.custody_by_classification?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        :receive="stratification.classifications.find(e=>e.classification_name.toUpperCase()=='TOTAL') ? 'R$ ' + stratification.classifications.findLast(e=>e.classification_name.toUpperCase()=='TOTAL').receive_by_classification.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '---'"
                        />
                    </b-col>
                </b-row>
            </template>

            <div style="height:100%; width:100%; overflow-x:hidden;">
                <b-row>
                    <b-col class="mt-3 py-2 mx-3 d-flex flex-column align-items-start justify-content-start" style="background-color: #262625; border: 1px solid #535252; border-radius: 10px;">
                        <span style="font-size: 28px; font-weight: 600;">Composição de ativos da carteira</span>
                        <div class="chart-container mb-4" style="position: relative;">
                            <canvas id="myChart"></canvas>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-3 mx-3 d-flex flex-column align-items-start justify-content-start" style="background-color: #262625; border: 1px solid #535252; border-radius: 10px;">
                        <span style="font-size: 28px; font-weight: 600;" class="mt-3">Detalhes</span>
                        <b-table borderless sticky-header style="max-height:55vh !important; width:100%;" :fields="columns" :items="stratification.classifications">
                            <template #cell(classification_name)="row">
                                <b-col class="text-left ml-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    {{ row.item.classification_name }}
                                </b-col>
                            </template>
                            <template #cell(volatility)="row">
                                <b-col class="text-left ml-0" style="margin-top: -5px;">
                                    {{ row.item.volatility }}
                                </b-col>
                            </template>
                            <template #cell(custody_by_classification)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    <span :style="isDisabledValidation(row.item, row.item.custody_by_classification) ? 'opacity:.05;' : ''">
                                        {{ Number(row.item.custody_by_classification) > 0 ? 'R$ ' + row.item.custody_by_classification.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00' }}
                                    </span>
                                </b-col>
                            </template>
                            <template #cell(percentage_wallet)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    <div class="px-2" v-if="row.item.classification_name !== 'RENDA FIXA'" :style="row.item.percentage_wallet < row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #CB3411;' : row.item.percentage_wallet > row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #28A745;' : row.item.percentage_wallet == row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #FFF;': ''">
                                        <span :style="isDisabledValidation(row.item, row.item.percentage_wallet) ? 'opacity:.05;' : ''">
                                            {{ row.item.percentage_wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' }}
                                        </span>
                                    </div>
                                    <div class="px-2" v-else :style="row.item.percentage_wallet < row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #28A745;' : row.item.percentage_wallet > row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #CB3411;' : row.item.percentage_wallet == row.item.ideal_percentage_wallet && row.item.classification_name !== 'Total' ? 'color: #FFF;': ''">
                                        <span :style="isDisabledValidation(row.item, row.item.percentage_wallet) ? 'opacity:.05;' : ''">
                                            {{ row.item.percentage_wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' }}
                                        </span>
                                    </div>
                                </b-col>
                            </template>
                            <template #cell(ideal_percentage_wallet)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;">
                                    <div class="px-2" style="border: 1px solid #EB694A; color: #EB694A;" :style="row.item.classification_name === 'Total' ? 'border: 0' : ''">
                                        <span :style="isDisabledValidation(row.item, row.item.ideal_percentage_wallet) ? 'opacity:.05;' : ''">
                                            {{ row.item.classification_name !== 'Total' && Number(row.item.ideal_percentage_wallet) >= 0 ? Number(row.item.ideal_percentage_wallet).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '' }}
                                        </span>
                                    </div>
                                </b-col>
                            </template>
                            <template #cell(roa_xp)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    <div class="px-2" :style="row.item.roa_xp < row.item.ideal_roa && row.item.classification_name !== 'Total' ? 'color: #CB3411;' : row.item.roa_xp > row.item.ideal_roa && row.item.classification_name !== 'Total' ? 'color: #28A745;' : row.item.roa_xp == row.item.ideal_roa && row.item.classification_name !== 'Total' ? 'color: #FFF;': ''">
                                        <span :style="isDisabledValidation(row.item, row.item.roa_xp) ? 'opacity:.05;' : ''">
                                            {{ row.item.roa_xp.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' }}
                                        </span>
                                    </div>
                                </b-col>
                            </template>
                            <template #cell(ideal_roa)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;">
                                    <div class="px-2" style="border: 1px solid #EB694A; color: #EB694A;" :style="row.item.classification_name === 'Total' ? 'border: 0' : ''">
                                        <span :style="isDisabledValidation(row.item, row.item.ideal_roa) ? 'opacity:.05;' : ''">
                                            {{ row.item.classification_name !== 'Total' && Number(row.item.ideal_roa) >= 0 ? Number(row.item.ideal_roa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '' }}
                                        </span>
                                    </div>
                                </b-col>
                            </template>
                            <template #cell(receive_by_classification)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    {{ 'R$ ' + Number(row.item.receive_by_classification).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                </b-col>
                            </template>
                            <template #cell(percentage_receive)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;" :style="row.item.classification_name === 'Total' ? 'margin-top: 17px; margin-bottom: 25px; color: rgb(235, 105, 74);' : ''">
                                    {{ row.item.percentage_receive.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' }}
                                </b-col>
                            </template>
                            <template #cell(opportunity)="row">
                                <b-col class="text-right pr-0" style="margin-top: -5px;">
                                    <div class="px-2" style="background-color: #EB694A;" v-if="row.item.opportunity > 0">
                                        {{ row.item.classification_name !== 'Total' ? 'R$ ' + Number(row.item.opportunity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '' }}
                                    </div>
                                    <div class="px-2" style="background-color: #EB694A;" v-else>
                                        {{ row.item.classification_name !== 'Total' ? '-' : '' }}
                                    </div>
                                </b-col>
                            </template>
                            <template #cell(show_details)="row">
                                <div class="d-flex justify-content-end align-items-center" style="margin-top: -10px !important;">
                                    <b-button 
                                        size="md"
                                        style="color: #000; border: none; box-shadow: none; background-color: transparent;"
                                        @click="showDetailsByClassification(row)"
                                        v-if="row.item.classification_name !== 'Total'"
                                    >
                                        <b-icon :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'" style="color: #EB694A;"></b-icon>
                                    </b-button>
                                </div>
                                <!-- <div class="d-flex justify-content-center align-items-center ml-1" style="margin-top: -10px !important;" v-if="row.item.classification_name !== 'Total'">
                                    <a href="#" @click="showDetails(null, null, row.item.classification_name, row.item.classification_name, row.item, true)"><img src="@/assets/visibility.png" alt=""></a>
                                </div> -->
                            </template>
                            <template #row-details="row">
                                <b-table-simple>
                                    <b-tbody>
                                        <b-tr v-for="item in row.item.subClassifications" :key="item.subclassification_name">
                                            <b-th style="width: 15%; cursor: pointer;"  @click="showDetails(item.classification_id, item.subclassification_id, item.classification_name, item.subclassification_name, item, true)">{{ item.subclassification_name }}</b-th>
                                            <b-th style="width: 8%; text-align: left;">{{ item.classification_id == 3 ? item.subclassification_name == 'RENDA FIXA / BAIXA VOLATILIDADE' ? 'de 0 a 2%' : item.subclassification_name == 'RENDA FIXA / MEDIA ALTA VOLATILIDADE' ? 'acima de 2%' : item.subclassification_name == 'MULTIMERCADO / BAIXA VOLATILIDADE' ? 'até 4%' : item.subclassification_name == 'MULTIMERCADO / MÉDIA VOLATILIDADE' ? 'de 4,01 a 8%' : item.subclassification_name == 'MULTIMERCADO / ALTA VOLATILIDADE' ? 'acima de 8%' : item.subclassification_name == 'MULTIMERCADO INTERNACIONAL / MÉDIA VOLATILIDADE' ? 'até 8%' : item.subclassification_name == 'MULTIMERCADO INTERNACIONAL / ALTA VOLATILIDADE' ? 'acima de 8%' : item.subclassification_name == 'AÇÕES' ? 'Sem faixa' : item.subclassification_name == 'AÇÕES INTERNACIONAL' ? 'Sem faixa' : '' : '' }}</b-th>
                                            <b-th style="width: 12%; text-align: right; padding-right: 30px;">{{ item.custody_by_classification_and_subclassification != null ? 'R$ ' + item.custody_by_classification_and_subclassification.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00' }}</b-th>
                                            <b-th style="width: 10%; text-align: right; padding-right: 35px;">
                                                <div v-if="item.classification_id != 4" class="px-2" :style="item.percentage_wallet < item.ideal_percentage_wallet ? 'color: #CB3411;' : item.percentage_wallet > item.ideal_percentage_wallet ? 'color: #28A745;' : item.percentage_wallet == item.ideal_percentage_wallet ? 'color: #FFF;': ''">
                                                    {{ item.percentage_wallet != null ? item.percentage_wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}
                                                </div>
                                                <div v-else class="px-2" :style="item.percentage_wallet < item.ideal_percentage_wallet ? 'color: #28A745;' : item.percentage_wallet > item.ideal_percentage_wallet ? 'color: #CB3411;' : item.percentage_wallet == item.ideal_percentage_wallet ? 'color: #FFF;': ''">
                                                    {{ item.percentage_wallet != null ? item.percentage_wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}
                                                </div>
                                            </b-th>
                                            <b-th style="width: 7%; text-align: right; padding-right: 30px; padding-left: 21px;">
                                                <div class="px-2" style="border: 1px solid #EB694A; color: #EB694A;">
                                                    {{ item.ideal_percentage_wallet != null ? Number(item.ideal_percentage_wallet).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}
                                                </div>
                                            </b-th>
                                            <b-th style="width: 7%; text-align: right; padding-right: 30px;">
                                                <div class="px-2" :style="item.roa_xp < item.ideal_roa ? 'color: #CB3411;' : item.roa_xp > item.ideal_roa ? 'color: #28A745;' : item.roa_xp == item.ideal_roa ? 'color: #FFF;': ''">
                                                    {{ item.roa_xp != null ? item.roa_xp.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}
                                                </div>
                                            </b-th>
                                            <b-th style="width: 7%; text-align: right; padding-right: 30px; padding-left: 27px;">
                                                <div class="px-2" style="border: 1px solid #EB694A; color: #EB694A;">
                                                    {{ item.ideal_roa != null ? Number(item.ideal_roa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}
                                                </div>
                                            </b-th>
                                            <b-th style="width: 10%; text-align: right; padding-right: 32px;">{{ item.receive_by_classification_and_subclassification != null ? 'R$ ' + Number(item.receive_by_classification_and_subclassification).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00' }}</b-th>
                                            <b-th style="width: 10%; text-align: right; padding-right: 37px;">{{ item.percentage_receive != null ? item.percentage_receive.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%' }}</b-th>
                                            <b-th style="width: 8%; text-align: right; padding-right: 0px;">
                                                <div class="px-2 ml-1" style="background-color: #EB694A;" v-if="item.opportunity > 0">
                                                    {{ item.opportunity != null ? 'R$ ' + Number(item.opportunity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00' }}
                                                </div>
                                                <div class="px-2 ml-1" style="background-color: #EB694A;" v-else>
                                                    -
                                                </div>
                                            </b-th>
                                            <b-th style="width: 5%; text-align: right;"><a href="#" @click="showDetails(item.classification_id, item.subclassification_id, item.classification_name, item.subclassification_name, item, true)"><img src="@/assets/visibility.png" alt=""></a></b-th>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col style="height: 20px;"></b-col>
                </b-row>

            </div>

            <template #footer>
                <div></div>
            </template>

        </DefaultPageComponent>

        <DefaultPageComponent 
        :title="'Detalhes'"
        :haveBackButton="true"
        @onBack="hideDetails"
        v-show="details"
        :loading="loading"
        :haveContentMargin="true"
        >
            <template #filters>
                <b-row class="mb-3">
                    <b-col class="d-flex" v-if="filterBy === 'company'" style="font-size: 28px; font-weight: 600; color: #EB694A;">
                        Visão WFLOW
                    </b-col>
                    <b-col class="d-flex" v-else-if="filterBy === 'cell' && subclassification.advisor === ''" style="font-size: 28px; font-weight: 600; color: #EB694A;">
                        Visão Equipe {{ subclassification.cell }}
                    </b-col>
                    <b-col class="d-flex" v-else-if="filterBy === 'cell' && subclassification.advisor !== ''" style="font-size: 28px; font-weight: 600; color: #EB694A;">
                        Visão Equipe {{ subclassification.cell }} - {{ subclassification.advisor }}
                    </b-col>
                    <b-col class="d-flex" v-else style="font-size: 28px; font-weight: 600; color: #EB694A;">
                        Visão Assessor {{ subclassification.advisor }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex col-10">
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">Classificação</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="subclassification.classification" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2">
                            <label for="">Subclassificação</label>
                            <input type="text" class="form-control form-control-sm mr-2" style="box-shadow: none; min-height:56px;" v-model="subclassification.subclassification" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2">
                            <label for="inputPesquisarPor">Filtrar por:</label>
                            <select @change="clearFilterSelects" class="form-control form-control-sm mr-2" id="inputPesquisarPor" style="box-shadow: none; min-height:56px;" v-model="filterByDetails">
                                <option value="">Selecione</option>
                                <option value="customer">Cliente</option>
                                <option value="roa">ROA</option>
                                <option value="ndocument">CNPJ</option>
                                <option value="active">Ativo</option>
                                <option value="issuer" v-if="subclassification.classification === 'RENDA FIXA'">Emissor</option>
                                <option value="due_date" v-if="subclassification.classification === 'RENDA FIXA'">Data de Vencimento</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'customer'">
                            <label for="">Cliente</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByCustomer" style="box-shadow: none; min-height:56px;"  v-model="filters.customer" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(customer, idx) in customers" :value="customer.code" :key="idx">{{ customer.advisor_name }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'roa'">
                            <label for="">ROA</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByROA" style="box-shadow: none; min-height:56px;" v-model="filters.roa" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(roa, idx) in roas" :value="roa.ROA_FUNDS" :key="idx">{{ roa.ROA_FUNDS }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'ndocument'">
                            <label for="">CNPJ</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByNDocument" style="box-shadow: none; min-height:56px;" v-model="filters.ndocument" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(ndocument, idx) in ndocuments" :value="ndocument" :key="idx">{{ ndocument }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'active'">
                            <label for="">Ativo</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByActive" style="box-shadow: none; min-height:56px;" v-model="filters.active" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(active, idx) in actives" :value="active.active" :key="idx">{{ active.active }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'issuer'">
                            <label for="">Emissor</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByIssuer" style="box-shadow: none; min-height:56px;" v-model="filters.issuer" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(issuer, idx) in issuers" :value="issuer.issuer" :key="idx">{{ issuer.issuer }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2" v-if="filterByDetails === 'due_date'">
                            <label for="">Data de Vencimento</label>
                            <select class="form-control form-control-sm mr-2" id="inputFilterByIssuer" style="box-shadow: none; min-height:56px;" v-model="filters.due_date" @change="filterSubClassificationsWithRequest(1)">
                                <option value="">Selecione</option>
                                <option v-for="(due_date, idx) in due_dates" :value="due_date.due_date" :key="idx">{{ formatDateBR(due_date.due_date) }}</option>
                            </select>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 px-2 flex-1" v-if="filterByDetails === ''">
                        </b-col>
                    </b-col>
                    <b-col class="d-flex col-2">
                        <b-col class="d-flex flex-column p-0 pl-2" style="height: 100%; display: flex; justify-content: center; margin-top: 15px; align-items: end;">
                            <b-form-checkbox switch size="md" v-model="customerFeeFixo" @change="showDetails(subclassification.classificationId, subclassification.subClassificationId, subclassification.classification, subclassification.subclassification, item)">Ignorar Fee Fixo</b-form-checkbox>
                        </b-col>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col class="d-flex col-12 mt-2">
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">Custódia</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.custody_by_classification_and_subclassification" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">% da carteira</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.percentage_wallet" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">% ideal</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.ideal_percentage_wallet" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">ROA XP</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.roa_xp" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">ROA ideal</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.ideal_roa" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">Total receita</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.receive_by_classification_and_subclassification" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">% de receita</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.percentage_receive" disabled>
                        </b-col>
                        <b-col class="d-flex flex-column p-0 pr-2">
                            <label for="">Oportunidade</label>
                            <input type="text" class="form-control form-control-sm" style="box-shadow: none; min-height:56px;" v-model="originData.opportunity" disabled>
                        </b-col>
                    </b-col>
                </b-row>
            </template>



            <div style="height:100%; width:100%; overflow-x:hidden;">
                <b-row style="height:100%;">
                    <b-col class="mx-3 d-flex flex-column align-items-start justify-content-start" style="background-color: #262625; border: 1px solid #535252; border-radius: 10px; height:100%;">
                        <span style="font-size: 28px; font-weight: 600;" class="mt-3">Detalhes</span>
                        <b-table id="tableDetalhes" ref="detailsTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" borderless sticky-header style="max-height:none !important; height: calc(100% - 100px) !important; width:100%;" show-empty empty-text="Não há dados para serem apresentados" :fields="subclassification.classificationId == 1 || subclassification.classificationId == 3 ? columnsSubclassificationWithRoaFunds : subclassification.classificationId == 4 ? columnsSubclassificationRendaFixa : subclassification.classificationId==14 ? columnsSubclassificationOutros : columnsSubclassification" :items="subclassification.subclassifications" :busy="loading">
                            <template #head(active)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #head(issuer)="data">
                                <b-col class="text-right p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #head(due_date)="data">
                                <b-col class="text-right p-0">
                                    {{data.label}}
                                </b-col>
                            </template>
                            <template #head(subproduct)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                </b-col>
                            </template>
                            <template #head(product)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                </b-col>
                            </template>
                            <template #head(customer)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                </b-col>
                            </template>
                            <template #head(n_document_fund)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #head(roa_funds)="data">
                                <b-col class="text-right p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #head(quantity)="data">
                                <b-col class="text-left p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #head(net)="data">
                                <b-col class="text-right p-0">
                                    {{data.label}}
                                    <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                                </b-col>
                            </template>
                            <template #cell(n_document_fund)="row">
                                <b-col class="text-right p-0">
                                    {{ formatCNPJ(row.item.n_document_fund) }}
                                </b-col>
                            </template>
                            <template #cell(roa_funds)="row">
                                <b-col class="text-right p-0">
                                    {{ Number(row.item.roa_funds).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' }}
                                </b-col>
                            </template>
                            <template #cell(due_date)="row">
                                <b-col class="text-right p-0">
                                    {{ formatDateBR(row.item.due_date) }}
                                </b-col>
                            </template>
                            <template #cell(quantity)="row">
                                <b-col class="text-right p-0">
                                    {{ Number(row.item.quantity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                </b-col>
                            </template>
                            <template #cell(net)="row">
                                <b-col class="text-right p-0">
                                    {{ 'R$ ' + Number(row.item.net).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                </b-col>
                            </template>
                            <template #cell(commission_value)="row">
                                <b-col class="text-right p-0">
                                    {{ 'R$ ' + Number(row.item.commission_value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                </b-col>
                            </template>
                            <template #table-busy>
                                <div class="d-flex align-items-center justify-content-center">
                                    <b-spinner class="mr-2"></b-spinner>
                                    <strong>Carregando...</strong>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </div>

            <template #footer>
                <div></div>
            </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import StratificationCard from './StratificationCard.vue';
import { mapState } from 'vuex';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';

export default {
    name: 'StratificationView',
    components:{
        DefaultPageComponent,
        StratificationCard,
    },
    data () {
        return {
            details: false,
            filterBy: 'company',
            cells: [],
            advisors: [],
            cellId: '',
            advisorId: '',
            chartInstance: null,
            customerFeeFixo: 0,
            stratification: {
                custody: 0,
                receive: 0,
                roa: 0,
                classifications: [],
            },
            columns: [
                {
                    'classification_name': 
                    {
                        label: 'Ativos',
                        thStyle: {
                            width: '15%'
                        }
                    }
                },
                {
                    'volatility': 
                    {
                        label: '',
                        thStyle: {
                            width: '9%',
                        }
                    }
                },
                {
                    'custody_by_classification': 
                    {
                        label: 'Custódia',
                        thStyle: {
                            width: '12%'
                        }
                    }
                },
                {
                    'percentage_wallet': 
                    {
                        label: '% da carteira',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'ideal_percentage_wallet': 
                    {
                        label: '% ideal',
                        thStyle: {
                            width: '7%'
                        }
                    }
                },
                {
                    'roa_xp': 
                    {
                        label: 'ROA XP',
                        thStyle: {
                            width: '7%'
                        }
                    }
                },
                {
                    'ideal_roa': 
                    {
                        label: 'ROA ideal',
                        thStyle: {
                            width: '7%'
                        }
                    }
                },
                {
                    'receive_by_classification': 
                    {
                        label: 'Total receita',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'percentage_receive': 
                    {
                        label: '% de receita',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'opportunity': 
                    {
                        label: 'Oportunidade',
                        thStyle: {
                            width: '8%'
                        }
                    }
                },
                {
                    'show_details': 
                    {
                        label: '',
                        thStyle: {
                            width: '5%'
                        }
                    }
                },
            ],
            
            columnsSubclassification: [
                {
                    'advisor_name': 
                    {
                        label: 'Assessor',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'customer_name': 
                    {
                        label: 'Cliente',
                        class: 'text-left',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'product': 
                    {
                        label: 'Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'Custódia',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
            ],
            columnsSubclassificationOutros: [
                {
                    'advisor_name': 
                    {
                        label: 'Assessor',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'customer_name': 
                    {
                        label: 'Cliente',
                        class: 'text-right',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'product': 
                    {
                        label: 'Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'Custódia',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'commission_value': 
                    {
                        label: 'Ajuste',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
            ],
            columnsSubclassificationRendaFixa: [
                {
                    'advisor_name': 
                    {
                        label: 'Assessor',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'customer_name': 
                    {
                        label: 'Cliente',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'product': 
                    {
                        label: 'Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        thStyle: {
                            width: '10%'
                        }
                    },
                    sortable: true,
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    },
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'due_date': 
                    {
                        label: 'Dt. Venc.',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'Custódia',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
            ],
            columnsSubclassificationWithRoaFunds: [
                {
                    'advisor_name': 
                    {
                        label: 'Assessor',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'customer_name': 
                    {
                        label: 'Cliente',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'product': 
                    {
                        label: 'Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub Produto',
                        thStyle: {
                            width: '10%'
                        }
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'roa_funds': 
                    {
                        label: 'ROA Fundos',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'Custódia',
                        thStyle: {
                            width: '10%'
                        },
                        sortable: true,
                    }
                },
            ],
            subclassification: {
                classificationId: '',
                subClassificationId: '',
                classification: '',
                subclassification: '',
                groupByNDocument: false,
                cell: '',
                advisor: '',
                totalNet: 0,
                totalCommissionValue: 0,
                subclassifications: [],
                subclassificationsbkp: [],
            },
            loading: false,
            customers: [],
            roas: [],
            ndocuments: [],
            actives: [],
            issuers: [],
            due_dates: [],
            periods: [],
            period: null,
            filters: {
                customer: '',
                roa: '',
                ndocument: '',
                active: '',
                issuer: '',
                due_date: '',
            },
            filterByDetails: '',
            originData: {},
            fieldFilters: {
                type: true,
                team: true,
                advisor: true,
                fee: true,
                period: true,
            },
            pageDetails: 1,
            lastPageDetails: 1,
            isScrollLoading: false,
            sortBy: null,
            sortDesc: true,
        }
    },
    async mounted() {
        try {
            this.loading = true;
            this.saveLog();
            await this.getPeriods();
            await this.getCells();
            await this.getAdvisors('', true);
            const intervalId = setInterval(() => {
                if(this.user){
                    clearInterval(intervalId);
                    if (!this.user.role) {
                        if (this.user.advisor_id) {
                            this.filterBy = 'cell';
                            this.cellId = this.user.cell_id;
                            this.advisorId = this.user.advisor_id;
                            this.fieldFilters.fee = false;
                            this.fieldFilters.period = false;
                            if (this.user.cells_leader_id.length > 0) {
                                this.fieldFilters.team = false;
                                this.cells = this.cells.filter(e => this.user.cells_leader_id.includes(e.id) || e.id == this.user.cell_id);

                                if (this.user.cells_leader_id.includes(this.user.cell_id)) {
                                    this.fieldFilters.advisor = false;
                                }

                                if (this.cellId > 0) {
                                    this.getAdvisors(this.cellId, true);
                                }
                            }

                            if (this.user.hunter_ids.length > 0) {
                                this.fieldFilters.type = false;
                            }
                        } else {
                            this.filterBy = 'cell';
                            this.fieldFilters.type = true;
                            this.fieldFilters.team = true;
                            this.fieldFilters.advisor = true;
                            this.fieldFilters.fee = true;
                            this.fieldFilters.period = true;
                            return this.$swal({
                                title: 'Atenção',
                                text: 'Não há um assessor vinculado a esse usuário',
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#28A745',
                                allowOutsideClick: false,
                            });
                        }
                    } else {
                        if (this.user.advisor_id) {
                            this.filterBy = 'cell';
                            this.cellId = this.user.cell_id;
                            if (this.cellId > 0) {
                                this.getAdvisors(this.cellId, true);
                            }
                            this.advisorId = this.user.advisor_id;
                            this.fieldFilters.type = false;
                            this.fieldFilters.team = false;
                            this.fieldFilters.advisor = false;
                            this.fieldFilters.fee = false;
                            this.fieldFilters.period = false;

                            
                        } else {
                            this.filterBy = 'company';
                            this.fieldFilters.type = false;
                            this.fieldFilters.team = false;
                            this.fieldFilters.advisor = false;
                            this.fieldFilters.fee = false;
                            this.fieldFilters.period = false;
                        }
                    }
                    this.getStratification();
                }
            }, 0);
        } catch (error) { 
            console.log(error); 
        } finally {
            this.loading = false;
        }
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        async onScroll(){
            const element = document.querySelector('#tableDetalhes').parentElement;
            if (element.scrollTop + 1500 >= element.scrollHeight 
                && element.scrollHeight>500 
                && !this.loading && !this.isScrollLoading
                && this.pageDetails < this.lastPageDetails
            ) {
                this.isScrollLoading=true;
                await this.filterSubClassificationsWithRequest(this.pageDetails+1, true)
            }
        },
        isDisabledValidation(item, value){
            let count = 0;
            this.stratification.classifications.forEach(item => {
                if (item.classification_name === 'Total')
                    return;
                count++;
            });
            const idx = this.stratification.classifications.findIndex(e => e.classification_id == item.classification_id);
            if (idx >= count - 9 && idx <= count && Number(value) == 0) 
                return true
            return false
        },
        async getPeriods(){
            const response = await this.$axios.get('diversifications/periods');
            this.periods = response.data.periods;
            if(this.periods.length>0)
                this.period = this.periods[0]
        },
        renderChart() {
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }

            const classificationsIDSPJ2 = [7, 23, 21, 15, 22, 24, 25, 17, 26, 27];
            let stratification = this.stratification.classifications
                .filter(item => item.classification_id && !classificationsIDSPJ2.includes(item.classification_id));
            stratification.sort((a, b) => a.POSITION - b.POSITION);
            const labels = stratification.map(item => item.classification_name + ' (' + Number(item.percentage_wallet).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })  + '%' + ')');
            const values = stratification.map(item => item.custody_by_classification);
            // const values = stratification.map(item => item.percentage_wallet);
            const ctx = document.getElementById('myChart').getContext('2d');
            this.chartInstance = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: '',
                        data: values,
                        backgroundColor: 'rgba(235, 105, 74, 1)',
                        borderColor: 'rgba(235, 105, 74, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    indexAxis: 'y',
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            min: 0,
                            // max: 100,
                            beginAtZero: true,
                            ticks: {
                                color: '#F3F0EF',
                                callback: function(value) {
                                    return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                }
                            }
                        },
                        y: {
                            ticks: {
                                color: '#F3F0EF',
                                font: {
                                    size: 15
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    var label = context.dataset.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += 'R$ ' + context.parsed.x.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                    return label;
                                }
                            }
                        },
                    }
                }
            });
        },
        getStratification () {
            const user = {...this.user};

            if (!user.advisor_id && !user.role) {
                this.cellId = "";
                this.advisorId = "";
                return;
            }

            this.loading = true;
            let url = "stratification/optimized";

            var queries = [`period=${this.period}`];
            
            if(this.cellId && this.filterBy === 'cell') {
                queries.push(`cell_id=${this.cellId}`);
            }

            if (this.advisorId) {
                queries.push(`advisor_id=${this.advisorId}`);
            } else if (this.filterBy === 'finder') {
                let ids = "";
                this.advisors.forEach(advisor => {
                    ids += advisor.id + ", ";
                });
                ids = ids.slice(0, -2);
                queries.push(`farmer_ids=${ids}`)
            }


            if (this.customerFeeFixo)
                queries.push(`customer_fee_fixo=S`);

            this.stratification.custody = 0;
            this.stratification.receive = 0;
            this.stratification.roa = 0;
            this.stratification.classifications = [];
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }
            this.$axios.get(url + (queries.length > 0 ? '?' + queries.join('&') : '')).then((response) => {
                this.stratification = response.data;
                this.renderChart();
                this.stratification.classifications.forEach(classification => {
                    classification.isLoading = false;
                })
            })
            .finally(() => { 
                this.loading=false; 
            });
        },
        async getStratificationsDetails (classificationId) {
            if ((!classificationId > 0)) {
                return;
            }

            const user = {...this.user};

            if (!user.advisor_id && !user.role) {
                this.cellId = "";
                this.advisorId = "";
                return;
            }

            let url = "stratification/classification";

            var queries = [`period=${this.period}`];
            
            if(this.cellId && this.filterBy === 'cell') {
                queries.push(`cell_id=${this.cellId}`);
            }

            if (this.advisorId) {
                queries.push(`advisor_id=${this.advisorId}`);
            } else if (this.filterBy === 'finder') {
                let ids = "";
                this.advisors.forEach(advisor => {
                    ids += advisor.id + ", ";
                });
                ids = ids.slice(0, -2);
                queries.push(`farmer_ids=${ids}`)
            }

            queries.push(`classification_id=${classificationId}`);

            if (this.customerFeeFixo)
                queries.push(`customer_fee_fixo=S`);

            this.loading = true;
            await this.$axios.get(url + (queries.length > 0 ? '?' + queries.join('&') : '')).then((response) => {
                this.stratification.classifications.forEach(classification => {
                    if (classification.classification_id == classificationId) {
                        classification.subClassifications = response.data.classifications[0].subClassifications
                    }
                });
            })
            .finally(() => { 
                this.loading=false; 
            });
        },
        async getCells () {
            const user = {...this.user};
            const response = await this.$axios.get('cells');
            this.cells = response.data.data;

            if (this.filterBy === 'finder' || !user.role) {
                this.cellId = user.cell_id;
                this.getAdvisors(this.cellId);
            }
        },
        async getAdvisors (cellId = '', mounted = false) {
            const user = {...this.user};
            const response = await this.$axios.get('advisors')
            this.advisors = response.data.data;
            if (cellId != '')
                this.advisors = this.advisors.filter(advisor => {
                    return advisor.cellHistories.length > 0 && advisor.cellHistories[0].cell.id === cellId
                });
            if (user.hunter_ids.length > 0 && this.filterBy == 'finder')
                this.advisors = this.advisors.filter(advisor => {
                    return user.hunter_ids.includes(advisor.id)
                });
                this.fieldFilters.advisor = false;

            if (cellId != '' && user.cells_leader_id.includes(cellId)) {
                this.fieldFilters.advisor = false;
            } else {
                if (!user.role && this.filterBy !== 'finder') {
                    this.fieldFilters.advisor = true;
                    this.advisorId = user.advisor_id;
                }
            }
            if (!mounted)
                this.getStratification();
        },
        async getFiltersList(){
            var response = null;
            var queryString = `?period=${this.period}&classification_id=${this.subclassification.classificationId}&sub_classification_id=${this.subclassification.subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}${this.customerFeeFixo ? '&customerFeeFixo=S' : ''}`;

            if (this.advisorId) {
                queryString += `&advisor_id=${this.advisorId}`
            }

            response = await this.$axios.get('stratification/customers' + queryString)
            this.customers = response.data.data;

            response = await this.$axios.get('stratification/roa-funds' + queryString)
            this.roas = response.data.data;

            response = await this.$axios.get('stratification/ndocuments' + queryString)
            this.ndocuments = response.data.data;

            response = await this.$axios.get('stratification/actives' + queryString)
            this.actives = response.data.data;

            response = await this.$axios.get('stratification/issuers' + queryString)
            this.issuers = response.data.data;

            response = await this.$axios.get('stratification/due-dates' + queryString)
            this.due_dates = response.data.data;
        },
        showDetails (classificationId, subClassificationId, classificationName, subClassificationName, item, firstTime = false) {
            this.originData.custody_by_classification_and_subclassification = item.custody_by_classification_and_subclassification != null ? 'R$ ' + item.custody_by_classification_and_subclassification.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00';
            this.originData.percentage_wallet = item.percentage_wallet != null ? item.percentage_wallet.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%';
            this.originData.ideal_percentage_wallet = item.ideal_percentage_wallet != null ? Number(item.ideal_percentage_wallet).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%';
            this.originData.roa_xp = item.roa_xp != null ? item.roa_xp.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%';
            this.originData.ideal_roa = item.ideal_roa != null ? Number(item.ideal_roa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%';
            this.originData.receive_by_classification_and_subclassification = item.receive_by_classification_and_subclassification != null ? 'R$ ' + Number(item.receive_by_classification_and_subclassification).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00';
            this.originData.percentage_receive = item.percentage_receive != null ? item.percentage_receive.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0%';
            this.originData.opportunity = item.opportunity != null ? item.opportunity < 0 ? '-' : 'R$ ' + item.opportunity.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'R$ 0,00';
            if (firstTime)
                this.subclassification.groupByNDocument = false;
            this.subclassification.classificationId = classificationId;
            this.subclassification.subClassificationId = subClassificationId;
            this.subclassification.classification = classificationName;
            this.subclassification.subclassification = subClassificationName;
            
            let url = "";
            if (classificationId !== null || subClassificationId !== null)
                if (this.customerFeeFixo)
                    url = `stratification/details?page=1&period=${this.period}&classification_id=${classificationId}&sub_classification_id=${subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}&customerFeeFixo=S`;
                else
                    url = `stratification/details?page=1&period=${this.period}&classification_id=${classificationId}&sub_classification_id=${subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}`;
            else
                if (this.customerFeeFixo)
                    url = `stratification/details-without-classification?classification_id=${classificationId}&sub_classification_id=${subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}&customerFeeFixo=S`;
                else
                    url = `stratification/details-without-classification?classification_id=${classificationId}&sub_classification_id=${subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}`;
            if (this.cellId != '' && this.filterBy == 'cell') {
                url += `&cell_id=${this.cellId}`;
                for (let i = 0; i < this.cells.length; i++) {
                    if (this.cells[i].id === this.cellId) {
                        this.subclassification.cell = this.cells[i].cell;
                    }
                } 
            }

            if (this.advisorId != '') {
                url += `&advisor_id=${this.advisorId}`;
                for (let i = 0; i < this.advisors.length; i++) {
                    if (this.advisors[i].id === this.advisorId) {
                       this.subclassification.advisor = this.advisors[i].first_name + " " + this.advisors[i].last_name;
                    }
                } 
            } else if (this.filterBy === 'finder') {
                let ids = "";
                this.advisors.forEach(advisor => {
                    ids += advisor.id + ", ";
                });
                ids = ids.slice(0, -2);
                url += `&farmer_ids=${ids}`;
            }

            this.loading = true;
            this.$axios.get(url).then((response) => {
                this.subclassification.subclassifications = response.data.data.data;
                this.subclassification.subclassificationsbkp = response.data.data.data;

                this.pageDetails = response.data.data.current_page;
                this.lastPageDetails = response.data.data.last_page;

                this.customers = [];
                this.roas = [];
                this.ndocuments = [];
                this.actives = [];
                this.issuers = [];
                this.due_dates = [];
                this.subclassification.subclassifications.forEach(item => {
                    const customer = item.customer_name;
                    const roa = Number(item.roa_funds).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    const ndocument = this.formatCNPJ(item.n_document_fund);
                    const active = item.active;
                    const issuer = item.issuer;
                    const due_date = item.due_date;

                    if (customer !== '' && customer !== null && !this.customers.includes(customer)) {
                        this.customers.push(customer);
                    }

                    if (roa !== '' && roa !== null && !this.roas.includes(roa)) {
                        this.roas.push(roa);
                    }

                    if (ndocument !== '' && ndocument !== null && !this.ndocuments.includes(ndocument)) {
                        this.ndocuments.push(ndocument);
                    }

                    if (active !== '' && active !== null && !this.actives.includes(active)) {
                        this.actives.push(active);
                    }

                    if (issuer !== '' && issuer !== null && !this.issuers.includes(issuer)) {
                        this.issuers.push(issuer);
                    }

                    if (due_date !== '' && due_date !== null && !this.due_dates.includes(due_date)) {
                        this.due_dates.push(due_date);
                    }
                });
                
                this.customers.sort((a, b) => a.localeCompare(b, 'pt'));
                this.ndocuments.sort((a, b) => a.localeCompare(b, 'pt'));
                this.roas.sort((a, b) => a.localeCompare(b, 'pt'));
                this.actives.sort((a, b) => a.localeCompare(b, 'pt'));
                this.issuers.sort((a, b) => a.localeCompare(b, 'pt'));
                this.due_dates.sort((a, b) => a.localeCompare(b, 'pt'));

                this.getFiltersList();

                const onScrollInterval = setInterval(()=>{ 
                if(this.details){
                    this.onScroll();
                }
                else clearInterval(onScrollInterval);
            },1000)
            }).finally(() => this.loading = false);
            this.filters.customer = '';
            this.filters.roa = '';
            this.filters.ndocument = '';
            this.filters.active = '';
            this.filters.issuer = '';
            this.filters.due_date = '';
            this.filterByDetails = '';
            this.details = true;
        },
        hideDetails () {
            this.subclassification.totalNet = 0;
            this.details = false;
            this.renderChart();
        },
        formatCNPJ(cnpj) {
            if (cnpj && cnpj.length === 14) {
                return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
            }
            return cnpj;
        },
        async filterSubClassificationsWithRequest(page, isScroll=false){
            try{
                if(isScroll) this.isScrollLoading=true;
                else this.loading=true;

                var filterStr = '';
                if(this.filterByDetails=='customer' && this.filters.customer){
                    filterStr = '&customer=' + this.filters.customer;
                }
                if(this.filterByDetails=='roa' && this.filters.roa){
                    filterStr = '&roa=' + this.filters.roa;
                }
                if(this.filterByDetails=='ndocument' && this.filters.ndocument){
                    filterStr = '&ndocument=' + this.filters.ndocument.replace(/\D/g, '');
                }
                if(this.filterByDetails=='active' && this.filters.active){
                    filterStr = '&active=' + this.filters.active;
                }
                if(this.filterByDetails=='issuer' && this.filters.issuer){
                    filterStr = '&issuer=' + this.filters.issuer;
                }
                if(this.filterByDetails=='due_date' && this.filters.due_date){
                    filterStr = '&due_date=' + this.filters.due_date;
                }

                if(page){
                    filterStr += '&page=' + page;
                }

                if(this.advisorId > 0) {
                    filterStr += '&advisor_id=' + this.advisorId;
                }
    
                var endpoint = `stratification/details?period=${this.period}&classification_id=${this.subclassification.classificationId}&sub_classification_id=${this.subclassification.subClassificationId}&groupByNDocument=${this.subclassification.groupByNDocument ? "S" : "N"}&${this.customerFeeFixo ? 'customerFeeFixo=S' : ''}`
                const response = await this.$axios.get(endpoint + filterStr);
                this.pageDetails = response.data.data.current_page;
                this.lastPageDetails = response.data.data.last_page;
                if(isScroll) this.subclassification.subclassifications.push(...response.data.data.data);
                else this.subclassification.subclassifications = response.data.data.data;
            }
            catch(error){ console.log(error); }
            finally{ 
                if(isScroll) this.isScrollLoading=false;
                else this.loading=false;
            }
        },
        clearFilterSelects(){
            this.filters.customer = '';
            this.filters.roa = '';
            this.filters.ndocument = '';
            this.filters.active = '';
            this.pageDetails=1;
            this.lastPageDetails=1;
            this.filterSubClassificationsWithRequest(1);
        },
        filterSubClassifications () {
            this.subclassification.subclassifications = this.subclassification.subclassificationsbkp;
            
            if (this.filters.customer !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => item.customer_name === this.filters.customer);
            }

            if (this.filters.roa !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => Number(item.roa_funds).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) === this.filters.roa);
            }

            if (this.filters.ndocument !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => this.formatCNPJ(item.n_document_fund) === this.filters.ndocument);
            }

            if (this.filters.active !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => item.active === this.filters.active);
            }

            if (this.filters.issuer !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => item.issuer === this.filters.issuer);
            }

            if (this.filters.due_date !== '') {
                this.subclassification.subclassifications = this.subclassification.subclassifications.filter(item => item.due_date === this.filters.due_date);
            }
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        saveLog () {
            let objLog = {
                description: 'Estratificação'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        showDetailsByClassification(row) {
            row.toggleDetails();
            if (row.item.subClassifications.length == 0)
                this.getStratificationsDetails(row.item.classification_id)
        }
    },
    watch: {
        'stratification': function () {
            // this.stratification.classifications.forEach(async (classification) => {
            //     await this.getStratificationsDetails(classification.classification_id);
            // });
        }
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }

    .chart-container {
        width: calc(100% - 10px) !important;
        /* min-height: 600px !important; */
    }

    table thead tr th {
        background: #535252 !important;
        color: #F3F0EF !important;
        padding: 2px 20px !important;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
    }

    table thead tr th:first-child {
        text-align: left;
    }

    table thead tr th:last-child {
    }

    table tbody tr td {
        background-color: #242323 !important;
        color: #F3F0EF !important;
        padding: 0px 20px !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }

    #tableDetalhes tbody tr td {
        background-color: #242323f0 !important;
    }

    table tbody tr {
        border-bottom: 1px solid #535252 !important;
    }

    table tbody tr:last-child {
        border-bottom: 0px solid #535252 !important;
    }

    table tbody tr th {
        background-color: #363636f0 !important;
        color: #F3F0EF !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }

    .b-table-sticky-header > .table.b-table > thead > tr > th {
        position: sticky !important;
        top: 0;
        z-index: 2;
    }
</style>
