<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Dias úteis & PTAX'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-start align-items-center mt-1">
                <div class="col-2">
                    <div class="form-group">
                        <label for="inputYear">Ano</label>
                        <select class="form-control form-control-sm" id="inputYear" style="box-shadow: none; min-height:56px;" v-model="filter.year">
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                </div>
                <b-col></b-col>
                <b-col cols="3" class="d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="search"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                </b-col>
            </div>
        </template>

        <!-- CONTENT -->
        <div class="container">
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="workingDaysPtax" show-empty empty-text="Sem registros para mostrar no momento...">
                
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-end align-items-center">
                        <!-- <b-form-checkbox switch size="sm" v-model="row.item.status" @change="activateOrInactivateBank(row.item, row.item.status)"></b-form-checkbox> -->
                        <b-button 
                            style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showModalEditWorkingDaysPTAX(row.item.id)"
                        >
                            <img src="@/assets/pencil.png" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

        <card-modal :title="montlyCalendar.formatted_date" ref="modalEditWorkingDaysPTAX">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-form @submit.stop.prevent="onSubmitEditWorkingDaysPTAX">
                        <div class="row d-flex justify-content-center mx-0">
                            <div class="col-md-5">
                                <b-form-group id="inputGroupBusinessDays" label="Dias úteis" label-for="inputGroupBusinessDays">
                                    <b-form-input
                                        id="inputGroupBusinessDays"
                                        name="inputGroupBusinessDays"
                                        v-model="montlyCalendar.business_days"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        :state="validateState('inputGroupBusinessDays')"
                                        aria-describedby="inputGroupBusinessDaysFeedback"
                                        data-vv-as="Dias úteis"
                                        :disabled="loading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="inputGroupBusinessDaysFeedback">{{ veeErrors.first('inputGroupBusinessDays') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-5">
                                <b-form-group id="inputGroupPTAX" label="PTAX" label-for="inputGroupPTAX">
                                    <b-form-input
                                        id="inputGroupPTAX"
                                        name="inputGroupPTAX"
                                        v-model="montlyCalendar.last_business_day_ptax"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        :state="validateState('inputGroupPTAX')"
                                        aria-describedby="inputGroupPTAXFeedback"
                                        data-vv-as="Dias úteis"
                                        disabled
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputGroupPTAXFeedback">{{ veeErrors.first('inputGroupPTAX') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-2">
                                <b-icon id="info-icon" icon="info-circle" style="color:rgb(235, 105, 74);"></b-icon>
                                    <b-tooltip target="info-icon" triggers="hover" variant="light">
                                    <b style="font-size:17px;"></b>
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-end mx-3 mt-3">
                            <CancelButton
                                @click="$refs.modalEditWorkingDaysPTAX.closeModal()"
                            >
                                Cancelar
                            </CancelButton>
                            <CustomButton
                                @click="onSubmitEditWorkingDaysPTAX"
                                :disabled="!montlyCalendar.id"
                                :loading="loading"
                            > 
                                {{ 'Salvar' }}
                            </CustomButton>
                        </div>
                    </b-form>
                </div>
            </div>
        </card-modal>

    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import CardModal from './CardModal.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';

export default {
    name: 'WorkingDaysPtaxView',
    components:{
        DefaultPageComponent,
        GlassButton,
        'card-modal': CardModal,
        CustomButton,
        CancelButton,
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputGroupBusinessDays: {
                    required: 'Campo obrigatório.',
                },
            },
        });
    },
    mounted() {
        this.getWorkingDaysPTAX(this.filter.year);
    },
    data() {
        return {
            loading:false,
            columns: [
                {
                    'year': 
                    {
                        label: 'Ano',
                        class: 'text-right'
                    }
                },
                {
                    'month': 
                    {
                        label: 'Mês',
                        class: 'text-right'
                    }
                },
                {
                    'business_days': 
                    {
                        label: 'Dias úteis',
                        class: 'text-right'
                    }
                },
                {
                    'last_business_day_ptax': 
                    {
                        label: 'PTAX',
                        class: 'text-right',
                        formatter: (value) => { return value.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4 }) }
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                    }
                },
            ],
            workingDaysPtax: [],
            filter: {
                year: 2025,
            },
            montlyCalendar: {
                id: '',
                year: '',
                month: '',
                business_days: '',
                last_business_day_ptax: '',
                formatted_date: '',
            },
            validateState(ref) {
                if (
                    this.veeFields[ref] &&
                    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
                ) {
                    return !this.veeErrors.has(ref);
                }
                return null;
            },
        }
    },
    methods: {
        getWorkingDaysPTAX() {
            this.loading=true;
            this.$axios.get('montly-calendar-ptax?year='+this.filter.year).then((response) => {
                this.workingDaysPtax = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível os dias úteis e ptax.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            });
        },
        showModalEditWorkingDaysPTAX(id) {
            this.loading=true;
            this.$axios.get('montly-calendar-ptax/'+id).then((response) => {
                this.montlyCalendar = response.data.data;
                this.$refs.modalEditWorkingDaysPTAX.openModal();
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o registro de dias úteis e ptax.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            });
        },
        onSubmitEditWorkingDaysPTAX() {
            this.loading=true;
            this.$axios.put('montly-calendar-ptax/'+this.montlyCalendar.id, this.montlyCalendar).then(() => {
                this.$refs.modalEditWorkingDaysPTAX.closeModal();
                this.getWorkingDaysPTAX(this.filter.year);
                this.$swal({
                    title: 'Sucesso',
                    text: 'Dias úteis atualizado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível atualizar o registro de dias úteis e ptax.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            });
        }
    },
    watch: {
        'filter.year': function () {
            this.getWorkingDaysPTAX(this.filter.year);
        }
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
