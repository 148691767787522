<template>
    <div v-dragscroll style="min-width:100%; overflow:auto; height: calc(100vh - 515px);">
        <b-col class="w-100" style="min-height:100%;">
            <b-row ref="chartContainer" style="margin-left:66px; height:400px; position: relative;">
                <canvas ref="chart" :id="chartKey"></canvas>
            </b-row>
            <table ref="dataTable">
                <thead>
                    <tr style="position:sticky; top:-3px; z-index: 99999; border: 3px solid black;">
                        <th style="min-width:325px; border:none;"></th>
                        <th v-for="item, idx in [...modal].sort((a,b)=>a.twelve_months_volatility - b.twelve_months_volatility)" :key="idx">
                            <div>{{ item.twelve_months_volatility }}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item, idx in [...modal].sort((a,b)=>a.twelve_months_volatility - b.twelve_months_volatility)" :key="idx">
                        <td style="position:sticky; left:0px; width:325px; text-align:start;" :style="idx%2==0 ? 'background: #181818 !important;' : ''"> 
                            {{ item.fund }} </td>
                        <td v-for="itemIntern, idxIntern in [...modal].sort((a,b)=>a.twelve_months_volatility - b.twelve_months_volatility)" :key="idxIntern" :style="idx%2==0 ? 'background: #181818 !important;' : ''">
                            {{ item.twelve_months_volatility==itemIntern.twelve_months_volatility ? item.absolute_return_12_months : '' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-col>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "DualGraphicsComponents",
    props:{
        loading: Boolean,
        chartKey: String,
        modal: Array,
        params: Array,
    },
    data() {
        return {
            listColumns: [],
            scatterInstance: null,
            config: {
                plugins: [ChartDataLabels],
                data: {
                    datasets: [
                        {
                            label: 'Ativo',
                            type: 'scatter',
                            data: [],
                            backgroundColor: '#eb694a',
                            borderColor: '#eb694a',
                            radius: 6,
                            datalabels: {
                                align: 'top',
                                color: '#eb694a',
                                formatter: function(value) {
                                    return value.y;
                                },
                                font:{
                                    size: 16
                                },
                            },
                        },
                        {
                            label: 'CDI',
                            type: 'line',
                            data: [],
                            backgroundColor: '#28A74580',
                            borderColor: '#28A74580',
                            radius: 0,
                            datalabels: {
                                align: 'left',
                                color: (context)=>{
                                    if(context.dataIndex==0) return '#28A745';
                                    else return 'transparent';
                                },
                                formatter: function(value) {
                                    return value.label+": "+value.y+"%"+'\r\r\r\r\r\r\r\r\r\r';
                                },
                                font:{
                                    size: 17,
                                    weight: 800
                                },
                            },
                        },
                        {
                            label: 'BOVESPA',
                            type: 'line',
                            data: [],
                            backgroundColor: '#28A74580',
                            borderColor: '#28A74580',
                            radius: 0,
                            datalabels: {
                                align: 'left',
                                color: (context)=>{
                                    if(context.dataIndex==0) return '#28A745';
                                    else return 'transparent';
                                },
                                formatter: function(value) {
                                    return value.label+": "+value.y+"%"+'\r\r\r\r\r\r\r\r\r\r';
                                },
                                font:{
                                    size: 17,
                                    weight: 800
                                },
                            },
                        },
                    ],
                },
                options: {
                    layout: {
                        padding: {
                            // left: 20,
                            left: 200,
                            bottom: 30,
                        }
                    },
                    plugins: {
                        legend:{
                            display: false,
                        },
                        tooltip:{
                            bodyFont:{
                                size: 16
                            },
                            callbacks: {
                                label: function(context) {
                                    let label = context.dataset.data[context.dataIndex].label || '';

                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed.y !== null) {
                                        label += 'retorno: '+context.parsed.y;
                                    }
                                    if (context.parsed.x !== null) {
                                        label += ' vol: '+context.dataset.data[context.dataIndex].x_real;
                                    }
                                    return label;
                                }
                            }
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: false,
                    scales: {
                        x: {
                            label: {
                                display:false,
                            },
                            ticks: {
                                stepSize: 0.5,
                                display: false,
                            },
                            grid: {
                                color: '#FFFFFF30',
                            },
                        },
                        y: {
                            suggestMin: 0,
                            ticks: {
                                color:'#FFFFFF60',
                                font:{
                                    size: 20
                                },
                                callback: function(value) {
                                    return value+'%';
                                }
                            },
                            grid: {
                                color: '#FFFFFF30'
                            }
                        }
                    },
                    animation: {}
                }
            },
        };
    },
    mounted() {
        setTimeout(() => {
            const intervalId = setInterval(() => {
                if (!this.loading) {
                    clearInterval(intervalId);
                    this.setupColumns();
                }
            }, 500);
        }, 1500);
    },
    methods: {
        setupColumns() {
            // ATIVOS
            const listActives = [...this.modal].sort((a,b)=>a.twelve_months_volatility - b.twelve_months_volatility).map((item,idx) => ({
                label: item.fund,
                x: (idx*0.5) + 0.25,
                y: item.absolute_return_12_months,
                x_real: item.twelve_months_volatility,
            }));
            this.config.data.datasets[0].data = listActives;

            // CDI
            const cdi = this.params.find(e=>e.name.toUpperCase()=="CDI")
            this.config.data.datasets[1].data = [
                {
                    label: cdi.name,
                    x: 0,
                    y: cdi.tax
                },
                {
                    label: cdi.name,
                    x: listActives[listActives.length-1].x + 0.25,
                    y: cdi.tax
                },
            ];

            // BOVESPA
            const bovespa = this.params.find(e=>e.name.toUpperCase()=="BOVESPA")
            this.config.data.datasets[2].data = [
                {
                    label: bovespa.name,
                    x: 0,
                    y: bovespa.tax
                },
                {
                    label: bovespa.name,
                    x: listActives[listActives.length-1].x + 0.25,
                    y: bovespa.tax
                },
            ];

            this.$nextTick(() => {
                this.resizeChart();
            });
        },
        resizeChart() {
            const table = this.$refs.dataTable;
            const chartContainer = this.$refs.chartContainer;
            if (!table || !chartContainer) return;

            const tableWidth = table.offsetWidth;

            const canvas = document.getElementById(this.chartKey);
            // canvas.width = tableWidth - 40;
            canvas.width = tableWidth - 60;
            canvas.height = 400;

            if (this.scatterInstance) {
                this.scatterInstance.destroy();
            }

            const xIndexes = this.modal.map((i,idx)=>(idx*0.5) + 0.25);

            // this.config.options.scales.x.min = -1;
            this.config.options.scales.x.min = 0;
            this.config.options.scales.x.max = Math.max(...xIndexes) + 0.25;
            this.config.options.scales.x.ticks.stepSize = 0.5;

            const ctx = canvas.getContext('2d');
            this.scatterInstance = new Chart(ctx, this.config);
        }
    }
};
</script>

<style scoped>
td, tr, th, table, thead, tbody {
    background: black !important;
    color: #eb694a !important;
}
td, th {
    border: 1px solid #FFFFFF30;
    text-align: center;
    min-width:100px;
}
</style>
